import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import AOS from 'aos';
import store from './store/store';
import { setUser } from './features/auth/authSlice';
import Login from './features/auth/Login';
import VerifyOtp from './features/auth/VerifyOtp';

import CandidateDetail from './features/attendance/CandidateDetail';


import Dashboard from './features/dashboard/Dashboard';
import Analytics from './features/analytics/Analytics';
import UserList from './features/user/UserList';
import UserForm from './features/user/UserForm';
import AlertBox from './features/alert/AlertBox';

import ProjectsList from './features/projects/ProjectsList';
import AddProjectData from './features/projects/AddProjectData';
import CloseProject from './features/projects/CloseProject';

import Ats from './features/ats/Ats';
import CandidateListing from './features/ats/CandidateListing';
import CreateJob from './features/job/CreateJob';
import JobList from './features/job/JobList';

import EmployeeList from './features/employee/EmployeeList';
import EmployeeProfile from './features/employee/EmployeeProfile';
import EmployeeExtension from './features/employee/EmployeeExtension';
import EmployeeAppraisal from './features/employee/EmployeeAppraisal';
import EmployeeContractClosure from './features/employee/EmployeeContractClosure'
import AttendanceIndex from './features/attendance/AttendanceIndex';
import TimeSheet from './features/attendance/TimeSheet';
import AttendanceDetails from './features/attendance/AttendanceDetails';
import JobDetails from './features/job/JobDetails/JobDetails';
import JobCardDetails from './features/job/JobCartsDetails/JobsCartsDetails';
import CandidateProfile from './features/Candidates/profile/CandidateProfile';
import ScheduleInterview from './features/scheduleInterview/ScheduleInterview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageAssessment from './features/job/ManageAssessment';
import Interviews from './features/Interviewer/Interviewer';
import PayrollIndex from './features/payroll/PayrollIndex';
import EmploymentTracker from './features/EmployeeTracker/EmployeeTracker';
import ProjectTrackers from './features/ProjectTracker/ProjectTracker';
import AlumniTracker from './features/EmployeeAlumniTracker/EmployeeAlumniTracker';

import AnalyticsDashboard from "./AnalyticiDashboard-files/AnalyticsDashboard"

import All from "./employee/all";
import Empaneled from "./employee/Empaneled";
import Contract from "./employee/Contract";
import Onboarding from "./employee/onboarding";
import Define from "./employee/define";
import People from "./employee/People";
import Appraisal from "./employee/Appraisal";
import Extension from "./employee/Extension";
import FullnFinal from "./employee/FnF";

// manish import

import Division from './features/division/Division';
import Region from './features/region/Region';
import Bank from './features/bank/Bank';
import Occupation from './features/occupation/Occupation';
import Dispensary from './features/dispensary/Dispensary';
import Tags from './features/tags/Tags';
import Education from './features/education/Education';
import AddLocation from './features/location/AddLocation';
import AddDesignation from './features/designation/AddDesignation';
import Duration from './features/duration/Duration';
import Department from './features/department/Department';
import State from './features/state/State'
import Benefits from './features/benefits/Benefits';
import Holiday from './features/holiday/Holiday';
import Cms from './features/cms/Cms';
import SalaryRange from './features/salaryRange/SalaryRange';
import Leave from './features/leave/Leave';
import GradeList from './features/GradeList/GradeList';
import ManPowerAcquisitions from './features/ManpowerAcuisuition/AddManPowers';
import ListManpowerRequisition from './features/ManpowerAcuisuition/ListofManPowers';
import MprFormValidation from './features/mprFormValidattion/mprForm';
import AssessmentList from './features/AssesmentLIst/AssesmentList';
import RQFormData from './features/ApprovedRQForm/RqFrom';

// Candidate Profile Data  ->>>>>>>>>>>>>>>>>>>>>>>>>>
import UpcomingInterview from "./candidate_pannel/src/components/UpcomingInterview";
import TodayInterview from "./candidate_pannel/src/components/TodayInterview";
import MyComponent from "./candidate_pannel/src/components/MyComponent";
import CandidateProfiles from "./candidate_pannel/src/components/CandidateProfile";
import RescheduleInterview from "./candidate_pannel/src/components/RescheduleInterview";
import MyProfile from "./candidate_pannel/src/components/MyProfile";
import AddRoleUsers from './features/RoleUsers/AddRoleUsers';
import RoleUserListing from './features/RoleUsers/RoleUsersList';
import AssignMenus from './features/RoleUsers/AssignMenu';
import ViewSalary from './features/attendance/ViewSalary';
import RunPayroll from './features/payroll/RunPayroll';
import SystemSetting from './features/SystemSetting/SystemSetting';
import AddCurrency from './features/Currency/CurrencyMaster';
import AllHeaders from './features/partials/AllHeaders';
import BatchId from './features/Batch/AddBatchId';
import ExpiredAlert from './ExpiredAlert';


const NavbarHandler = ({ children }) => {
  return (
    <>
      <MyComponent />
      {children}
    </>
  )
}




const hasAccess = (slug) => {
  let LoginUsersData = JSON.parse(localStorage.getItem('admin_role_user'));
  const route = LoginUsersData?.permissions?.find(route => route.slug === slug);
  return route ? true : false;
};


const RequireAuthEmployee = ({ children }) => {
  const employeeLogin = JSON.parse(window.sessionStorage.getItem("employeeLogin"));
  const employeewithLogin = JSON.parse(localStorage.getItem("employeeLogin"));
  const userId = employeeLogin ? employeeLogin._id : null;
  return userId || employeewithLogin ? children : <Navigate to="/login" />;
};

// const HandleNavbar = ({children}) => {
//     return (
//       <>
//          <AllHeaders />
//          { children }
//       </>
//     )
// }

const HandleTokenExpiredAlert = () => {
  const location = useLocation();
  const excludedRoutes = ["/login", "/verify-otp", "/mprFrm", "/rqForm"];

  const isExcluded = excludedRoutes.some((route) =>
    location.pathname.includes(route)
  );
  return !isExcluded && <ExpiredAlert />;
};






const RequireAuth = ({ children }) => {
  const existingSession = localStorage.getItem('admin_role_user');
  const dispatch = useDispatch();
  useEffect(() => {
    if (existingSession) {
      dispatch(setUser(JSON.parse(existingSession)));
    }
  }, [dispatch, existingSession]);

  return existingSession ? children : <Navigate to="/login" />;
};


// const clearCacheData = () => {
//   caches.keys().then((names) => {
//       names.forEach((name) => {
//           caches.delete(name);
//       });
//   });
//   alert("Complete Cache Cleared");
// };

const HandleNavbar = () => {
  const location = useLocation();
  const str = location.pathname;


  if(!str){
    return null;
  }
  if(
    str?.includes('/login') || 
    str?.includes('/mprFrm') || 
    str?.includes('/rqForm') || 
    str?.includes('/upcoming') || 
    str?.includes('/today-interview') || 
    str?.includes('/candidate-profiles') || 
    str?.includes('/reschedule')  || 
    str?.includes('/profile') ||
    str?.includes('/verify-otp') 
  ){
     return null
  }
  return  <AllHeaders />
}




const App = () => {
  // clearCacheData();
  // http://localhost:8080/v1/admin/verifyExistingToken
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <Provider store={store}>
      <AlertBox />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        theme="light"
      />



      <Router>
        < HandleNavbar />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path='/mprFrm/:id' element={<MprFormValidation />} />
          <Route exact path='/rqForm/:id' element={<RQFormData />} />
          {/* employee Pannel Routes */}
          <Route exact path='/upcoming' element={<RequireAuthEmployee> <NavbarHandler> <UpcomingInterview /> </NavbarHandler> </RequireAuthEmployee>} />
          <Route exact path='/today-interview' element={<RequireAuthEmployee> <NavbarHandler><TodayInterview /></NavbarHandler> </RequireAuthEmployee>} />
          <Route exact path='/candidate-profiles/:_id' element={<RequireAuthEmployee> <NavbarHandler> <CandidateProfiles /> </NavbarHandler> </RequireAuthEmployee>} />
          <Route exact path='/reschedule/:_id' element={<RequireAuthEmployee> <NavbarHandler>  <RescheduleInterview /> </NavbarHandler></RequireAuthEmployee>} />
          <Route exact path='/profile' element={<RequireAuthEmployee> <NavbarHandler>  <MyProfile /> </NavbarHandler></RequireAuthEmployee>} />


          <Route exact path="/verify-otp" element={<VerifyOtp />} />
          <Route exact path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route exact path="/analytics" element={hasAccess("analytics") ? (<RequireAuth><AnalyticsDashboard /></RequireAuth>) : (<Navigate to="/login" replace />)} />
          <Route exact path="/projects" element={hasAccess("projects") ? (<RequireAuth><ProjectsList /></RequireAuth>) : (<Navigate to="/login" replace />)} />
          <Route exact path="/add-project" element={hasAccess("add-project") ? (<RequireAuth><AddProjectData /></RequireAuth>) : (<Navigate to="/login" replace />)} />
          {/* <Route exact path="/add-project" element={<RequireAuth><AddProjectData /></RequireAuth>} /> */}
          <Route exact path="/close-project" element={<RequireAuth><CloseProject /></RequireAuth>} />
          <Route
            exact
            path="/ats"
            element={
              hasAccess("ats") ? (
                <RequireAuth>
                  <Ats />
                </RequireAuth>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route exact path="/candidate-listing" element={<RequireAuth><CandidateListing /></RequireAuth>} />
          <Route exact path="/create-job" element={<RequireAuth><CreateJob /></RequireAuth>} />
          <Route exact path="/employee-list" element={<RequireAuth><EmployeeList /></RequireAuth>} />
          <Route exact path="/employee-profile" element={<RequireAuth><EmployeeProfile /></RequireAuth>} />
          <Route exact path="/employee-extension" element={<RequireAuth><EmployeeExtension /></RequireAuth>} />
          <Route exact path="/system-settings" element={<RequireAuth><SystemSetting /></RequireAuth>} />
          <Route exact path="/currency" element={<RequireAuth><AddCurrency /></RequireAuth>} />

          {/* <Route exact path="/employee-appraisal" element={<RequireAuth><EmployeeAppraisal /></RequireAuth>} /> */}

          <Route
            exact
            path="/employee-appraisal"
            element={
              hasAccess("employee-appraisal") ? (
                <RequireAuth>
                  <EmployeeAppraisal />
                </RequireAuth>
              ) : (
                <Navigate to="/login" replace /> // Redirect to /login if access is denied
              )
            }
          />


          <Route exact path="/contract-closure" element={hasAccess('contract-closure') ? (<RequireAuth><EmployeeContractClosure /></RequireAuth>) : <Navigate to={'/login'} replace />} />
          <Route exact path="/attendance-index" element={hasAccess('attendance-index') ? (<RequireAuth><AttendanceIndex /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/time-sheet" element={<RequireAuth><TimeSheet /></RequireAuth>} />
          <Route exact path="/attendance-details" element={hasAccess('attendance-details') ? (<RequireAuth><AttendanceDetails /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          {/* <Route exact path="/time-sheet" element={<RequireAuth><TimeSheet /></RequireAuth>} /> */}
          {/* <Route exact path="/attendance-details" element={<RequireAuth><AttendanceDetails /></RequireAuth>} /> */}
          <Route exact path="/interviews" element={<RequireAuth><Interviews /></RequireAuth>} />
          <Route exact path="/assign-menu/:id" element={<RequireAuth> <AssignMenus /></RequireAuth>} />
          <Route exact path="/candidate-detail" element={<RequireAuth> <CandidateDetail /> </RequireAuth>} />
          <Route exact path="/job-details/:id" element={<RequireAuth><JobDetails /></RequireAuth>} />
          <Route exact path='/job-cards-details/:id' element={<RequireAuth><JobCardDetails /></RequireAuth>} />
          <Route exact path="/candidate-profile/:id" element={<RequireAuth> <CandidateProfile /> </RequireAuth>} />
          <Route exact path="/schedule-interview/:id" element={<RequireAuth> <ScheduleInterview /> </RequireAuth>} />
          <Route exact path="/assessment" element={hasAccess('assessment') ? (<RequireAuth><ManageAssessment /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/employementtracker" element={hasAccess('employementtracker') ? (<RequireAuth><EmploymentTracker /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/project-tracker" element={hasAccess('project-tracker') ? (<RequireAuth><ProjectTrackers /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/employee-alumni-tracker" element={hasAccess('employee-alumni-tracker') ? (<RequireAuth><AlumniTracker /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/gradelist" element={hasAccess('gradelist') ? (<RequireAuth><GradeList /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/manpower-acquisition" element={hasAccess('manpower-acquisition') ? (<RequireAuth><ManPowerAcquisitions /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/manpower-acquisition-list" element={hasAccess('manpower-acquisition-list') ? (<RequireAuth><ListManpowerRequisition /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/assessment-list" element={hasAccess('assessment-list') ? (<RequireAuth><AssessmentList /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/add-role-user" element={hasAccess('add-role-user') ? (<RequireAuth><AddRoleUsers /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/role-users-list" element={hasAccess('role-users-list') ? (<RequireAuth><RoleUserListing /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />


          <Route exact path="/run-payroll" element={<RequireAuth> <RunPayroll /> </RequireAuth>} />

          <Route exact path="/payroll" element={hasAccess('payroll') ? (<RequireAuth><PayrollIndex /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/peoples" element={hasAccess('People') ? (<RequireAuth><All /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/Empaneled" element={hasAccess('Empaneled') ? (<RequireAuth><Empaneled /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/fnf" element={hasAccess('fnf') ? (<RequireAuth><FullnFinal /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/Contract" element={hasAccess('Contract') ? (<RequireAuth><Contract /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/Appraisal" element={hasAccess('Appraisal') ? (<RequireAuth><Appraisal /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/Extension" element={hasAccess('Extension') ? (<RequireAuth><Extension /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />



          {/* <Route exact path="/peoples" element={<RequireAuth><All /></RequireAuth>} /> */}
          {/* <Route exact path="/fnf" element={<RequireAuth><FullnFinal /></RequireAuth>} /> */}
          {/* <Route exact path="/Empaneled" element={<RequireAuth><Empaneled /></RequireAuth>} /> */}
          {/* <Route exact path="/Contract" element={<RequireAuth><Contract /></RequireAuth>} /> */}
          <Route exact path="/Onboarding" element={<RequireAuth><Onboarding /></RequireAuth>} />
          <Route exact path="/salary" element={<RequireAuth><Define /></RequireAuth>} />
          <Route exact path="/people-profile" element={<RequireAuth><People /></RequireAuth>} />
          {/* <Route exact path="/Appraisal" element={<RequireAuth><Appraisal /></RequireAuth>} /> */}
          {/* <Route exact path="/Extension" element={<RequireAuth><Extension /></RequireAuth>} /> */}


          {/* manish routes */}

          <Route exact path="/add-location" element={hasAccess('add-location') ? (<RequireAuth><AddLocation /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/add-designation" element={hasAccess('add-designation') ? (<RequireAuth><AddDesignation /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/duration" element={hasAccess('duration') ? (<RequireAuth><Duration /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/department" element={hasAccess('department') ? (<RequireAuth><Department /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/state" element={hasAccess('state') ? (<RequireAuth><State /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/division" element={hasAccess('division') ? (<RequireAuth><Division /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/region" element={hasAccess('region') ? (<RequireAuth><Region /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/bank" element={hasAccess('bank') ? (<RequireAuth>  <Bank /> </RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/dispensary" element={hasAccess('dispensary') ? (<RequireAuth><Dispensary /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/occupation" element={hasAccess('occupation') ? (<RequireAuth><Occupation /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/tags" element={hasAccess('tags') ? (<RequireAuth><Tags /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/education" element={hasAccess('education') ? (<RequireAuth><Education /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/benefits" element={hasAccess('benefits') ? (<RequireAuth><Benefits /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/holiday" element={hasAccess('holiday') ? (<RequireAuth><Holiday /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/cms" element={hasAccess('cms') ? (<RequireAuth><Cms /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/salary-range" element={hasAccess('salary-range') ? (<RequireAuth><SalaryRange /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          <Route exact path="/leave" element={hasAccess('leave') ? (<RequireAuth><Leave /></RequireAuth>) : (<Navigate to={'/login'} replace />)} />
          {/* <Route exact path="/add-batch-id" element={hasAccess('batch-id') ? (<RequireAuth><Leave /></RequireAuth>) : (<Navigate to={'/login'} replace />)} /> */}


          {/* <Route exact path="/add-location" element={<RequireAuth> <AddLocation /> </RequireAuth>} />
          <Route exact path="/add-designation" element={<RequireAuth> <AddDesignation /> </RequireAuth>} /> */}
          {/* <Route exact path="/duration" element={<RequireAuth> <Duration /> </RequireAuth>} />
          <Route exact path="/department" element={<RequireAuth> <Department /> </RequireAuth>} /> */}
          {/* <Route exact path="/state" element={<RequireAuth> <State /> </RequireAuth>} /> */}
          {/* <Route exact path="/division" element={<RequireAuth> <Division /> </RequireAuth>} /> */}
          {/* <Route exact path="/region" element={<RequireAuth> <Region /> </RequireAuth>} /> */}
          {/* <Route exact path="/bank" element={<RequireAuth> <Bank /> </RequireAuth>} /> */}
          {/* <Route exact path="/dispensary" element={<RequireAuth> <Dispensary /> </RequireAuth>} /> */}
          {/* <Route exact path="/occupation" element={<RequireAuth> <Occupation /> </RequireAuth>} /> */}
          {/* <Route exact path="/tags" element={<RequireAuth> <Tags /> </RequireAuth>} /> */}
          {/* <Route exact path="/education" element={<RequireAuth> <Education /> </RequireAuth>} /> */}
          {/* <Route exact path="/benefits" element={<RequireAuth> <Benefits /> </RequireAuth>} /> */}
          {/* <Route exact path="/holiday" element={<RequireAuth> <Holiday /> </RequireAuth>} /> */}
          {/* <Route exact path="/cms" element={<RequireAuth> <Cms /> </RequireAuth>} /> */}
          {/* <Route exact path="/salary-range" element={<RequireAuth> <SalaryRange /></RequireAuth>} /> */}
          <Route exact path="/batch-id" element={<RequireAuth> <BatchId /></RequireAuth>} />

          <Route exact path='/view-salary' element={<RequireAuth>< ViewSalary /></RequireAuth>} />





          <Route exact path="/job-list" element={<RequireAuth><JobList /></RequireAuth>} />
          <Route exact path="/users" element={<RequireAuth> <UserList /> </RequireAuth>} />
          <Route exact path="/user/new" element={<RequireAuth> <UserForm /> </RequireAuth>} />
          <Route exact path="/user/:id/edit" element={<RequireAuth> <UserForm /> </RequireAuth>} />
          <Route exact path="*" element={<Navigate to="/dashboard" />} />

          {/* Interview Schedule and ReSchedule Pannal */}

          {/* <Route exact path='/' element={<Login />} />
          <Route exact path='/login' element={<Login />} /> */}


          {/* <Route exact path='*' element={<Navigate to="/login" />} /> */}

        </Routes>
        <HandleTokenExpiredAlert />
      </Router>
    </Provider>
  );
};

export default App;
