import { React, useEffect, useState } from "react";
import { FaCheckCircle, FaLinkedin } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { RiTwitterXFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { CgProfile } from "react-icons/cg";
import GoBackButton from "../../goBack/GoBackButton";
import { FaEye, FaFolderOpen, FaStar, FaUpload } from "react-icons/fa6";
// import Rate_modal from "./Ratings/Rating-modal";
import RateModal from "../../Rating/RatingModels";
import InterviewSteps from "../InterviewSteps";
import CandidateResume from "../CandidateResume";
import AllHeaders from "../../partials/AllHeaders";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FetchCandidatesListById } from "../../slices/AppliedJobCandidates/JobAppliedCandidateSlice";
import config from "../../../config/config";
import moment from "moment";
import { changeJobTypeLabel, DateFormate } from "../../../utils/common";
import FeedbackModels from "./FeedBackModles";
import Accordion from 'react-bootstrap/Accordion';
import { Button, Card, Col, Form, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import ToggleButton from 'react-toggle-button';
import { toast } from "react-toastify";
import axios from "axios";
import { apiHeaderToken, apiHeaderTokenMultiPart } from "../../../config/api_header";
import CandidateChats from "./CandidateChats";



export default function CandidateProfile() {
  const color = ["bg_redlt", "bg_magentalt", "bg_purple", "bg_greenlt"];
  const [modalShow, setModalShow] = useState(false);
  const [selectedData, setData] = useState(false);
  const [profileStatus, setProfileStatus] = useState('Active')
  const { id } = useParams();
  const candidateRecords = useSelector((state) => state.appliedJobList.AppliedCandidateList)
  const dispatch = useDispatch();
  const [imgPreview, setImgPreview] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [docType, setDocType] = useState('')
  const [docName, setDocName] = useState('')
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(FetchCandidatesListById(id));
  }, [id, dispatch])

  const handleShowRateModels = (e, data) => {
    e.preventDefault()
    setModalShow(true);
    setData(data)
  }
  // Handle the Toggle Button As we Expected ->>>>>>>>>>>>

  const handleToggleStatus = async (gradeItem) => {
    const newStatus = gradeItem?.profile_status === 'Active' ? 'Blocked' : 'Active';
    const payload = { candidate_id: gradeItem?._id, profile_status: newStatus };
    try {
      let response = await axios.post(`${config.API_URL}changeCandidateProfileStatus`, payload, apiHeaderToken(config.API_TOKEN));
      if (response.status === 200) {
        dispatch(FetchCandidatesListById(id));
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error updating grade status:", error);
      toast.error(error?.response.data?.message);
    }
  };

  const handleModalOpen = (e, imageUrl) => {
    e.preventDefault();
    setImgPreview(true);
    let ImageData = config.IMAGE_PATH + imageUrl;
    console.log(ImageData, 'this is ImageURL Data');
    setImageUrl(ImageData)
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!docType) {
      return toast.warn('Please Select the Document Type')
    }
    if (!docName) {
      return toast.warn('Please Select the Document Name')
    }
    if (!file) {
      return toast.warn('Please Select the Document Name');
    }

    setLoading(true);

    let Payloads = {
      "_id": candidateRecords.data?._id,
      "doc_category": docType,
      "sub_doc_category": '',
      "doc_name": docName,
      "filename": file
    }

    if (docType === 'KYC') {
      Payloads.sub_doc_category = 'Document'
    } else if (docType === 'Educational') {
      Payloads.sub_doc_category = 'Marksheet'
    } else if (docType === 'Experience') {
      Payloads.sub_doc_category = 'Experience Latter'
    } else if (docType === 'Certificates') {
      Payloads.sub_doc_category = 'Skill'
    } else if (docType === 'Bank') {
      Payloads.sub_doc_category = 'Bank'
    }

    try {
      let response = await axios.post(`${config.CANDIDATE_URL}uploadKycDocs`, Payloads, apiHeaderTokenMultiPart(config.API_TOKEN))

      if (response.status === 200 && response.data?.status) {
        setLoading(false);
        toast.success(response?.data?.message)
        dispatch(FetchCandidatesListById(id));
      } else {
        toast.warn(response?.data?.message)
        setLoading(false);
      }
    } catch (error) {
      console.log(error, 'this is Uploads Error');
      toast.error(error?.response?.data?.message)
      setLoading(false);
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [docUrl, setDocUrl] = useState('');

  const handleImageClick = (url) => {
    // alert(url);
    setDocUrl(url);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };



  return (
    <>
      {/* <AllHeaders /> */}
      <div className="maincontent">
        <div className="container" data-aos="fade-in" data-aos-duration="3000">
          <GoBackButton />
          <div className="sitecard">
            <div className="cd_profilebox d-flex">
              <div className="cd_prfleft">
                <div className="prfimg">
                  {
                    candidateRecords.status === 'success' && candidateRecords.data?.photo ?
                      <img style={{ maxHeight: '150px', maxWidth: '150px' }} src={`${config.IMAGE_PATH}${candidateRecords.status === 'success' && candidateRecords.data?.photo}`} alt="candidate images" />
                      : <CgProfile size={60} style={{ marginBottom: '2rem' }} />
                  }
                  <span className="rat_tag">{candidateRecords.status === 'success' && candidateRecords.data?.complete_profile_status}%</span>
                </div>
                <div className="name_rating">
                  <h4 className="name">{candidateRecords.status === 'success' && candidateRecords.data?.name}</h4>
                  <div className="rat_text">
                    <h6>Candidate rating</h6>
                    <p className="ratenum">
                      <FaStar />
                      <span>{candidateRecords.status === 'success' && candidateRecords.data?.profile_avg_rating}</span>
                    </p>
                  </div>
                  <div className="rat_text mt-3">
                    <h6><span>{candidateRecords.status === 'success' && candidateRecords.data?.profile_status}</span>
                    </h6>
                    <p className="ratenum">
                      {/* <span>{candidateRecords.status === 'success' && candidateRecords.data?.profile_status}</span> */}
                      <div className=''>
                        <ToggleButton
                          value={candidateRecords.data?.profile_status === 'Active'}
                          onToggle={() => handleToggleStatus(candidateRecords.data)}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="cd_prfright d-flex">
                <div className="cnt_info">
                  <h6>Contact Information</h6>
                  <p>{candidateRecords.status === 'success' && candidateRecords.data?.mobile_no}</p>
                  <p>
                    {candidateRecords.status === 'success' && candidateRecords.data?.email}
                  </p>
                  <p> {candidateRecords.status === 'success' && candidateRecords.data?.location}</p>
                  {/* <ul className="social">
                    {
                      candidateRecords.status === 'success' && candidateRecords.data?.social_links?.length > 0 && candidateRecords.data?.social_links?.map((item) => {
                        return (
                          <>
                            <li>
                              <a href="#">
                                <FaLinkedin />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <RiTwitterXFill />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <FaFacebook />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <FaInstagram />
                              </a>
                            </li>
                          </>
                        )
                      })
                    }
                  </ul> */}
                </div>
                <div className="cnt_info prev_empinfo">
                  <h6>Previous Employer</h6>
                  <p>{candidateRecords.status === 'success' && candidateRecords.data?.current_employer}</p>
                  <p>{candidateRecords.status === 'success' && candidateRecords.data?.designation} </p>
                  <p>
                    <a href="#">-</a>
                  </p>
                  <p>
                    <a href="mailto:">{candidateRecords.status === 'success' && candidateRecords.data?.email}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" className="mt-3" defaultActiveKey="first">
            <Nav variant="pills" className="flex-row postedjobs widthcomp tabsborder justify-content-between width-auto">
              <Nav.Item>
                <Nav.Link eventKey="first">Candidates Info</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="zero">Document</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Interviews</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Score Comparison Sheet</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">Feedback</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="five">Assessment</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="six">Application History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="seven">Discussion</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="int_process_tabs">
              <Tab.Pane eventKey="first">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className="sitecard pr-0">
                      <div className="infobox">
                        <h5>Personal Information</h5>
                        <div className="infotext">
                          <div className="infos">
                            <h6>Current Employer</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.current_employer}</p>
                          </div>
                          <div className="infos">
                            <h6>Designation</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.designation}</p>
                          </div>
                          <div className="infos">
                            <h6>Experience in Total</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.total_experience}</p>
                          </div>
                          <div className="infos">
                            <h6>Relevant Experience</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.relevant_experience}</p>
                          </div>
                          <div className="infos">
                            <h6>Current CTC</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.current_ctc} LPA</p>
                          </div>
                          <div className="infos">
                            <h6>Expected CTC</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.expected_ctc} LPA</p>
                          </div>
                          <div className="infos">
                            <h6>Notice Period</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.notice_period} Days</p>
                          </div>
                          <div className="infos">
                            <h6>Last Working day </h6>
                            <p>{candidateRecords.status === 'success' && moment(candidateRecords.data?.last_working_day).format("DD/MM/YYYY")}</p>
                          </div>
                          <div className="infos">
                            <h6>Applied from</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.applied_from}</p>
                          </div>
                          <div className="infos">
                            <h6>Reference employee</h6>
                            <p>{candidateRecords.status === 'success' && candidateRecords.data?.reference_employee}</p>
                          </div>
                        </div>
                        <h5>Education</h5>
                        <div className="infotext">
                          {
                            candidateRecords.status === 'success' && candidateRecords.data?.education !== 0
                            && candidateRecords.data?.education.map((value, index) => {
                              return (
                                <>
                                  <div className="infos" key={index}>
                                    <h6>{value.degree}</h6>
                                    <p>{value.institute}</p>
                                    <p>From {moment(value.from_date).format("YYYY")} - {moment(value.to_date).format("YYYY")}</p>
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className="sitecard h-100">
                      <div className="infobox">
                        <div className="d-flex flex-column gap-3 mt-1 scroller-content w-100">
                          {
                            candidateRecords.status === 'success' && candidateRecords?.data?.applied_jobs?.length !== 0 ?
                              candidateRecords.data?.applied_jobs?.map((item, index) => {
                                return (
                                  <>
                                    <div className="card hr_jobcards card-border me-2" key={index}>
                                      <div className="card-body">
                                        <div className="d-flex flex-column gap-3">
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex flex-column gap-1 contenter">
                                              <div className="location">
                                                <span className="text-start w-100 d-flex">
                                                  {changeJobTypeLabel(item?.job_type)}
                                                </span>
                                              </div>
                                              <h3 className="text-start mb-0">
                                                {item?.job_title}
                                              </h3>
                                              <span className="text-start">
                                                {candidateRecords?.data?.location}
                                              </span>
                                            </div>
                                            {/* button and drop down details here */}
                                            <div className="d-flex flex-row">
                                              <div className="d-flex flex-column gap-2">
                                                <Link to={`/job-details/${item.job_id}`} className="detaibtn">
                                                  View Detail
                                                </Link>
                                                <span className="datetime">
                                                  {DateFormate(item.add_date)}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }) : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="six">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className="sitecard h-100">
                      <div className="infobox">
                        <div className="d-flex flex-column gap-3 mt-1 scroller-content w-100">
                          <Accordion>

                            {
                              candidateRecords.status === 'success' && candidateRecords.data?.applied_jobs?.length !== 0 ?
                                candidateRecords.data?.applied_jobs?.map((item, index) => {
                                  return (
                                    <>
                                      <Accordion.Item eventKey={index}>
                                        <Accordion.Header key={index}>{item?.project_name || 'N/A'} | {item?.job_designation || 'N/A'} |  {changeJobTypeLabel(item?.job_type) || 'N/A'} | {changeJobTypeLabel(item?.form_status) || 'N/A'}  </Accordion.Header>
                                        <Accordion.Body>
                                          {
                                            item?.profile_details ?
                                              (
                                                <ListGroup style={{ 'fontSize': '13px' }}>
                                                  <ListGroup.Item><strong>Resume File:</strong> {item?.profile_details.resume_file} <span> <Button onClick={(e) => handleModalOpen(e, item?.profile_details.resume_file)} style={{ height: '30px', width: '60px', marginLeft: '10px', fontSize: '13px' }}> View </Button> </span></ListGroup.Item>
                                                  <ListGroup.Item><strong>Designation:</strong> {item?.profile_details.designation}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Total Experience:</strong> {item?.profile_details.total_experience}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Relevant Experience:</strong> {item?.profile_details.relevant_experience}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Location:</strong> {item?.profile_details.location}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Current CTC:</strong> {item?.profile_details.current_ctc} LPA</ListGroup.Item>
                                                  <ListGroup.Item><strong>Expected CTC:</strong> {item?.profile_details.expected_ctc} LPA</ListGroup.Item>
                                                  <ListGroup.Item><strong>Notice Period:</strong> {item?.profile_details.notice_period} Month(s)</ListGroup.Item>
                                                  <ListGroup.Item><strong>Current Employer:</strong> {item?.profile_details.current_employer}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Current Employer Mobile:</strong> {item?.profile_details.current_employer_mobile}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Last Working Day:</strong> {new Date(item?.profile_details.last_working_day).toLocaleDateString()}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Applied From:</strong> {item?.profile_details.applied_from}</ListGroup.Item>
                                                  <ListGroup.Item><strong>Reference Employee:</strong> {item?.profile_details.reference_employee}</ListGroup.Item>
                                                </ListGroup>
                                              )
                                              : "N/A"
                                          }
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </>
                                  )
                                })
                                : null
                            }
                          </Accordion>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="zero">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className="sitecard h-100">
                      <div className="infobox">
                        <div className="document-box">
                          <Row className="mb-4">
                            <div className="d-flex justify-content-around flex-wrap mb-5">
                              <Form.Group controlId="documentType">
                                <Form.Label>Select Document Type</Form.Label>
                                <Form.Control
                                  as="select"
                                  value={docType}
                                  onChange={(e) => setDocType(e.target.value)}
                                  style={{ width: '200px' }}
                                >
                                  <option value="">Choose...</option>
                                  <option value="KYC">KYC</option>
                                  <option value="Educational">Educational</option>
                                  <option value="Experience">Experience</option>
                                  <option value="Certificates">Certificates</option>
                                  <option value="Bank">Bank</option>
                                </Form.Control>
                              </Form.Group>

                              <Form.Group controlId="documentName">
                                <Form.Label>Enter Document Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Document Name"
                                  value={docName}
                                  onChange={(e) => setDocName(e.target.value)}
                                />
                              </Form.Group>

                              <Form.Group controlId="fileUpload">
                                <Form.Label>Choose Document</Form.Label>
                                <Form.Control
                                  type="file"
                                  accept=".jpeg ,.jpg , .png"
                                  onChange={handleFileChange}
                                />
                              </Form.Group>

                              <div className="" style={{ marginTop: '37px' }}>
                                <Button
                                  variant="primary"
                                  type="button"
                                  onClick={() => handleUpload()}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <>
                                      <Spinner animation="border" size="sm" /> Uploading...
                                    </>
                                  ) : (
                                    "Upload"
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Row>

                          {/* Document Listing Table */}
                          {/* <hr />
                          <h4 className="text-center">Document(s)</h4> */}
                          <hr />
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Size</th>
                                <th>Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidateRecords.status === 'success' && candidateRecords.data?.docs?.length > 0 ? candidateRecords.data?.docs?.map((doc, index) => (
                                <tr key={index}>
                                  <td>{doc.doc_category}</td>
                                  <td className={doc.status === 'upload' ? 'text-danger' : ''}>{doc.doc_name}</td>
                                  <td>{doc.file_size}</td>
                                  <td>{moment(doc.add_date).format('DD/MM/YYYY')}</td>
                                  <td>
                                    <FaEye onClick={(e) => handleImageClick(`${config.IMAGE_PATH}${doc?.file_name}`)}
                                    />
                                  </td>
                                </tr>
                              )) : <tr colSpan={6}>
                                <td colSpan={6}>No Data Available</td>
                              </tr>}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className="sitecard pr-0 h-100 ps-0 pt-4">
                      <div className="infobox">
                        <h5 className="ms-3">Score Comparison Sheet</h5>
                        <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100 candd_table smalldata">
                          <Table hover>
                            <thead>
                              <tr>
                                <th>Interviewer Name</th>
                                <th>Skills</th>
                                <th>Communication</th>
                                <th>Rating</th>
                                <th>Total interviewer </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                candidateRecords.status === 'success' && candidateRecords.data?.applied_jobs[0]?.interviewer.map((value, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{value?.employee_name}({value?.designation})</td>
                                        <td>{value?.skills}</td>
                                        <td>{value?.communication}</td>
                                        <td>{value?.rating}</td>
                                        <td>{candidateRecords.data?.applied_jobs[0]?.interviewer?.length}</td>
                                      </tr>
                                    </>
                                  )
                                })
                              }
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className="sitecard pr-0 h-100 ps-0 pt-4">
                      <div className="infobox">
                        <h5 className="ms-3">Interview Timeline</h5>
                        <div className="d-flex flex-column gap-2 mt-1 scroller-content candd_table w-100 smalldata">
                          <Table hover>
                            <thead>
                              <tr>
                                <th >Interview Date</th>
                                <th>Interviewer(s)</th>
                                <th>Round</th>
                                <th>Rating</th>
                                <th>Status</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                candidateRecords.status === 'success' && candidateRecords.data?.applied_jobs[0]?.interviewer.map((value, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{value?.interview_date ? moment(value?.interview_date).format("DD/MM/YYYY") : moment(candidateRecords.data?.applied_jobs[0]?.interview_date).format("DD-MMMM-YYYY")}</td>
                                        <td>{value?.employee_name}({value?.designation})</td>
                                        <td>{value?.stage}</td>
                                        <td>{value?.rating}</td>
                                        <td>
                                          {
                                            value?.feedback_status === 'Pending'
                                              ?
                                              <div className="d-flex align-items-center flex-column gap-1" onClick={(e) => handleShowRateModels(e, candidateRecords.data)}>
                                                <span className={`statused ${value?.feedback_status === 'Pending' ? 'bg_purple' : 'bg_greenlt'} `}>{value?.feedback_status}</span>
                                                <span className="updatedby">Updated by - {value?.added_by}</span>
                                              </div>
                                              : <div className="d-flex align-items-center flex-column gap-1">
                                                <span className={`statused ${value?.feedback_status === 'Pending' ? 'bg_purple' : 'bg_greenlt'} `}>{value?.feedback_status}</span>
                                                <span className="updatedby">Updated by - {value?.added_by}</span>
                                              </div>
                                          }
                                        </td>
                                        <td className="comment">
                                          <p> {value?.comment} </p>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })
                              }
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <div className="row my-3">
                  <div className="col-lg-8">
                    <div className=" pr-0 h-100">
                      <div className="">
                        <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100">
                          <div className="card card-border me-2">
                            <div className="card-body assemntbox">
                              {
                                candidateRecords.status === 'success' && candidateRecords.data?.assessment_result_data?.length > 0
                                  ?
                                  candidateRecords.data?.assessment_result_data?.map((item, index) => {
                                    return (
                                      <>
                                        <h5 className="text-center mb-0">
                                          {item?.type === 'MCQ' ? 'Employee Assessment Test' : 'HLFPPT QA'}
                                        </h5>
                                        <hr className="text-start" />
                                        <div className="d-flex align-items-center mb-4 justify-content-between">
                                          <h5 className="text-start mb-0">
                                            Assessment Status
                                          </h5>
                                          <span className={`${item?.result === "Pass" ? "statuses" : "failedStatus"}`} >
                                            {item?.result}
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5 className="text-start mb-0">
                                            Score
                                          </h5>
                                          <div className="stat-color">
                                            {item?.score?.toFixed(2)}%
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })
                                  :
                                  (<>
                                    <div className="d-flex align-items-center mb-4 justify-content-between">
                                      <h5 className="text-start mb-0">
                                        Assessment Status
                                      </h5>
                                      <span className="statuses">
                                        {candidateRecords.status === 'success' && candidateRecords.data?.assessment_status}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h5 className="text-start mb-0">
                                        Score
                                      </h5>
                                      <div className="stat-color">
                                        {candidateRecords.status === 'success' && candidateRecords.data?.score?.toFixed(2)}%
                                      </div>
                                    </div></>)
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <InterviewSteps interviewStep={candidateRecords.status === 'success' && candidateRecords.data} />
                    <CandidateResume resume={candidateRecords.status === 'success' && candidateRecords.data} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="seven">
                <CandidateChats />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <FeedbackModels show={modalShow} onHide={() => setModalShow(false)} selectedData={selectedData} />
        </div>
      </div>


      {/* Open Modal when user Added Document */}
      <Modal
        show={imgPreview}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setImgPreview(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Resume Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <div className="col-sm-12">
            <div className="uploadrow my-4">
              {imageUrl ? (
                imageUrl.endsWith('.pdf') ? (
                  <embed
                    src={imageUrl}
                    type="application/pdf"
                    className="img-fluid"
                    style={{ width: '100%', height: '500px' }} // Adjust height as needed
                  />
                ) : (
                  <img src={imageUrl} alt="Document" className="img-fluid" />
                )
              ) : (
                <p>No document available</p>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Show the Document Preview */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '300px', overflow: 'auto' }}>
            <img
              src={docUrl}
              alt="Selected"
              style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
