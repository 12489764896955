import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import config from "./config/config";
import axios from "axios";


const ExpiredAlert = () => {
    const navigate = useNavigate();

    const [tokenStatus, setTokenStatus] = useState(false);
    const location = useLocation(); 
  
    const CheckLogin = async () => {
      try {
        let response = await axios.post(`${config.API_URL}verifyExistingToken`, {
          token: config.API_TOKEN,
        });
        if (response.status === 200) {
          setTokenStatus(false); 
        } else {
          setTokenStatus(false);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setTokenStatus(true);
        } 
      }
    };
  
    useEffect(() => {
      CheckLogin();
    }, [location]);

    // setInterval(() => {
    //   setTokenStatus(true);
    // } , 10000)


    const handleLogout = () => {
        localStorage.removeItem("admin_role_user");
        setTokenStatus(false)
        navigate('/login')
    };


    return (
        <>
            <Modal show={tokenStatus} centered>
                <Modal.Header>
                    <Modal.Title>Session Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your session has expired. Please log in again to continue.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleLogout}>
                        Login Again
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ExpiredAlert