// import { React, useState , useEffect } from "react";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import StepConnector from "@mui/material/StepConnector";
// import { styled } from '@mui/system';
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import { stepConnectorClasses } from "@mui/material/StepConnector";
// import { Link } from 'react-router-dom';
// import { CamelCases , DateFormate } from "../../utils/common";

// const steps = ["Applied", "Shortlisted", "Interview", "Offer", "Hired"];
// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
//     [`&.${stepConnectorClasses.alternativeLabel}`]: {
//         top: 20,
//     },
//     [`&.${stepConnectorClasses.active}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             backgroundImage:
//                 "linear-gradient( 95deg,rgba(48, 169, 226, 1) 0%,rgba(48, 169, 226, 1) 50%,rgba(48, 169, 226, 1) 100%)",
//         },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             backgroundImage:
//                 "linear-gradient( 95deg,rgba(48, 169, 226, 1) 0%,rgba(48, 169, 226, 1) 50%,rgba(48, 169, 226, 1) 100%)",
//         },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//         width: 3,
//         height: 1,
//         border: 0,
//         backgroundColor:
//             theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
//         borderRadius: 1,
//     },
// }));


// const CustomStepIcon = ({ active, completed }) => {
//     if (completed) {
//         return <CheckCircleIcon color="primary" />;
//     } else if (active) {
//         return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
//             <path d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM8.73782 11.5C8.73782 13.0255 9.97449 14.2622 11.5 14.2622C13.0255 14.2622 14.2622 13.0255 14.2622 11.5C14.2622 9.97449 13.0255 8.73782 11.5 8.73782C9.97449 8.73782 8.73782 9.97449 8.73782 11.5Z" fill="#30A9E2" />
//         </svg>;
//     } else {
//         return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
//             <path d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM8.73782 11.5C8.73782 13.0255 9.97449 14.2622 11.5 14.2622C13.0255 14.2622 14.2622 13.0255 14.2622 11.5C14.2622 9.97449 13.0255 8.73782 11.5 8.73782C9.97449 8.73782 8.73782 9.97449 8.73782 11.5Z" fill="#EBEBEB" />
//         </svg>;
//     }
// };


// export default function InterviewSteps({interviewStep}) {
//     const [activeStep, setActiveStep] = useState(0);

//     useEffect(() => {
//         if (interviewStep && interviewStep?.applied_jobs[0]?.form_status) {
//             const currentStepIndex = steps.indexOf(interviewStep?.applied_jobs[0]?.form_status);
//             if (currentStepIndex !== -1) {
//                 setActiveStep(currentStepIndex);
//             }
//         }
//     }, [interviewStep]);

//     const handleNext = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     };

//     return (
//         <>
//             <div className="sitecard pr-0 ps-0 mb-3">
//                     <div className="d-flex flex-column steps_intervw_hdr gap-1 px-3">
//                         <div className="location">
//                             <span>{interviewStep && CamelCases(interviewStep?.applied_jobs[0]?.interview_type)}</span>
//                         </div>
//                         <h4 className="mb-0">{interviewStep && interviewStep?.applied_jobs[0]?.job_title}</h4>
//                         <div className="dflexbtwn">
//                             <span> { interviewStep && interviewStep?.location } </span>
//                             <span>{ interviewStep && DateFormate(interviewStep?.applied_jobs[0]?.add_date) }</span>
//                         </div>
//                     </div>
//                     <Box sx={{ width: "100%" }} className="px-3">
//                         <Stepper
//                             activeStep={activeStep}
//                             connector={<ColorlibConnector />}
//                             orientation="vertical"
//                         >
//                             {/* Your steps */}
//                             {steps.map((label, index) => (
//                                 <Step key={label}>
//                                     <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
//                                 </Step>
//                             ))}
//                         </Stepper>
//                     </Box>
//                     <div className="read-btn w-100 mt-3 px-3">
//                         <Link to={`/schedule-interview/${interviewStep?.job_id}?userId=${interviewStep?._id}&applied-job-id=${interviewStep && interviewStep?.applied_jobs[0]?._id}`}>
//                             <button className="btn" onClick={handleNext}>Schedule Interview</button>
//                         </Link>
//                     </div>
//             </div>
//         </>
//     );
// }

import { React, useState, useEffect } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DeleteAndRemoved, ShortListCandidates } from "../slices/JobSortLIstedSlice/SortLIstedSlice";
import { FetchAppliedCandidateDetails, FetchCandidatesListById } from "../slices/AppliedJobCandidates/JobAppliedCandidateSlice";
import { useDispatch } from "react-redux";
import StepConnector from "@mui/material/StepConnector";
import { styled } from '@mui/system';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { Link } from 'react-router-dom';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { CamelCases, changeJobTypeLabel, DateFormate } from "../../utils/common";
import moment from "moment";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { InputGroup } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa6";
import { LiaRupeeSignSolid } from "react-icons/lia";
import axios from "axios";
import config from "../../config/config";
import { apiHeaderToken } from "../../config/api_header";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


//const steps = ["Applied", "Shortlisted", "Interview", "Offer", "Hired"];



const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg,rgba(48, 169, 226, 1) 0%,rgba(48, 169, 226, 1) 50%,rgba(48, 169, 226, 1) 100%)",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg,rgba(48, 169, 226, 1) 0%,rgba(48, 169, 226, 1) 50%,rgba(48, 169, 226, 1) 100%)",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        width: 3,
        height: 1,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));


const CustomStepIcon = ({ active, completed }) => {
    if (completed) {
        return <CheckCircleIcon color="primary" />;
    } else if (active) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM8.73782 11.5C8.73782 13.0255 9.97449 14.2622 11.5 14.2622C13.0255 14.2622 14.2622 13.0255 14.2622 11.5C14.2622 9.97449 13.0255 8.73782 11.5 8.73782C9.97449 8.73782 8.73782 9.97449 8.73782 11.5Z" fill="#30A9E2" />
        </svg>;
    } else {
        return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM8.73782 11.5C8.73782 13.0255 9.97449 14.2622 11.5 14.2622C13.0255 14.2622 14.2622 13.0255 14.2622 11.5C14.2622 9.97449 13.0255 8.73782 11.5 8.73782C9.97449 8.73782 8.73782 9.97449 8.73782 11.5Z" fill="#EBEBEB" />
        </svg>;
    }
};


export default function Interview_steps({ interviewStep }) {
    const [activeStep, setActiveStep] = useState(0);
    const [isStepperDisabled, setIsStepperDisabled] = useState(false); // State to manage stepper disabled
    const { id } = useParams();
    const [offerModel, setOfferModel] = useState(false);
    const [offerData, setOfferData] = useState(null);
    const [offerDate, setOfferDate] = useState(null);
    const [offerDesignation, setOfferDesignation] = useState('');
    const [offerCTC, setOfferCTC] = useState(0);
    const Navigate = useNavigate();
    const [extendOffer, setExtendOffer] = useState(false);
    const [extendData, setextendData] = useState(null);

    const dispatch = useDispatch();
    const getEmployeeRecords = JSON.parse(localStorage.getItem('admin_role_user') ?? {})

    const handleCloseOfferModels = () => setOfferModel(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


    const HandleNavigate = () => {
        Navigate(`/onboarding?type=new&candidate_id=${interviewStep?._id}&job_id=${interviewStep?.job_id}`)
    }

    const handleReject = () => {
        let payloads = {
            "candidate_id": interviewStep?._id,
            "applied_job_id": interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id,
            "status": "Rejected"
        }
        dispatch(DeleteAndRemoved(payloads))
            .unwrap()
            .then((response) => {
                // console.log(response , 'this is response Id from the there are some issue reflated the name ')
                if (response.status) {
                    setIsStepperDisabled(true);
                    dispatch(FetchCandidatesListById(id));
                    handleNext()
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    /********* Handle the Reject the Point Data from the server  ***********/
    const handleHired = () => {
        let payloads = {
            "candidate_id": interviewStep?._id,
            "applied_job_id": interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id,
            "status": "Hired"
        }
        dispatch(DeleteAndRemoved(payloads))
            .unwrap()
            .then((response) => {
                if (response.status) {
                    setIsStepperDisabled(true);
                    dispatch(FetchCandidatesListById(id));
                    handleNext()
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };


    const handleShortListCandidate = async () => {
        let payloads = {
            "role_user_id": getEmployeeRecords?._id,
            "candidate_ids": [{ candidate_id: interviewStep?._id, applied_job_id: interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id }],
            "status": "Shortlisted",
            "batch_id": ''
        }
        dispatch(ShortListCandidates(payloads))
            .unwrap()
            .then((response) => {
                if (response.status) {
                    dispatch(FetchCandidatesListById(id));
                    handleNext()
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    const handleShowOfferModel = (e) => {
        setOfferModel(true);
        setOfferData(interviewStep)
        setOfferDesignation(interviewStep?.designation)
        setOfferCTC()
    }

    const ExtendOfferLatter = (e) => {
        setOfferModel(true);
        setOfferData(interviewStep)
        setOfferDesignation(interviewStep?.designation)
        setOfferCTC(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.offer_ctc)
        setExtendOffer(true);
        setextendData(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id))
        setOfferDate(
            moment(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.onboard_date).format('YYYY-MM-DD')
        );
        console.log(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id), 'this is Offer Obtain by user')
    }



    const steps = [
        {
            label: 'Applied',
            // description: moment(interviewStep?.add_date).utc().format('DD/MM/YYYY, h:mm a'),
            description: (
                <>
                    <p>{moment(interviewStep?.add_date).utc().format('DD/MM/YYYY, h:mm a')}</p>
                    {
                        interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.form_status === 'Applied' &&
                        <>
                            <button className="stepbtn bggren" onClick={handleShortListCandidate}> Mark Shortlisted</button>
                            <button className="stepbtn bgred" onClick={() => handleReject()}> Reject</button>
                        </>
                    }
                </>
            ),
            btntext: `Mark Shortlisted`,
            status: interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status
        },
        {
            label: 'Shortlisted',
            description: (
                <>

                    {
                        interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status === 'Shortlisted' &&
                        <>
                            <p>{moment(interviewStep?.updated_on).utc().format('DD/MM/YYYY, h:mm a')}</p>
                            <Link to={`/schedule-interview/${interviewStep?.job_id}?userId=${interviewStep?._id}&applied-job-id=${interviewStep && interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id}`}><button className="stepbtn"> Schedule Interview</button> </Link>
                            <button className="stepbtn bgred" onClick={() => handleReject()}> Reject</button>
                        </>
                    }
                </>
            ),
            btntext: `Schedule for Interview`,
            status: interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status
        },
        {
            label: 'Interview',
            description: (
                <>
                    {
                        interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status === 'Interview' &&
                        <>
                            <Link to={`/schedule-interview/${interviewStep?.job_id}?userId=${interviewStep?._id}&applied-job-id=${interviewStep && interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id}`}><button className="stepbtn"> Schedule Interview</button> </Link>
                            <button className="stepbtn bggren" onClick={handleShowOfferModel}> Send Offer </button>
                            <button className="stepbtn bgred" onClick={() => handleReject()}> Reject</button>
                        </>
                    }
                </>
            ),
            btntext: `Proceed to Offer`,
            status: interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status
        },
        {
            label: 'Offer',
            description:
                (
                    <>
                        {
                            interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status === 'Offer' &&
                            <>
                                {/* <button className="stepbtn bggren" onClick={HandleNavigate}> Start Onboarding </button> */}
                                {/* <button className="stepbtn bggren" onClick={handleHired}> Mark Hire </button> */}
                                {
                                    interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].offer_status === 'Accepted'
                                    &&
                                    <button className="stepbtn bggren" onClick={HandleNavigate}> Start Onboarding </button>
                                }
                                <button className="stepbtn bggren" onClick={ExtendOfferLatter}> Extend Offer </button>

                                <button className="stepbtn bgred" onClick={() => handleReject()}> Reject</button>
                            </>
                        }
                    </>
                ),
            btntext: ['Pending' , 'Rejected'].includes(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].offer_status) ? `Reject Candidate` : 'Start Onboarding',
            status: interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status
        },
        {
            label: 'Hired',
            description:
                (
                    <>
                        {
                            interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status === 'Hired'
                            // <>
                            //     <button className="stepbtn bggren" onClick={HandleNavigate}> Start Onboarding </button>

                            //     <button className="stepbtn bgred" onClick={() => handleReject()}> Reject</button>
                            // </>
                        }
                        {/* <button className="stepbtn bgred" onClick={() => handleReject()}> Reject</button> */}
                    </>
                ),
            btntext: `Candidate Hired`,
            status: interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status
        }
    ]

    useEffect(() => {
        if (interviewStep && interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status) {
            const formStatus = interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].form_status;
            if (formStatus === 'Rejected') {
                setIsStepperDisabled(true); // Disable all future steps if the candidate is rejected
            } else {
                setIsStepperDisabled(false); // Ensure future steps are enabled if not rejected
                const currentStepIndex = steps.findIndex(step => step.label === formStatus);
                if (currentStepIndex !== -1) {
                    setActiveStep(currentStepIndex);
                }
            }
        }
    }, [interviewStep]);

    const handleSendOfferLatter = () => {

        let payloads = {
            "candidate_id": interviewStep?._id,
            "applied_job_id": interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id,
            "onboard_date": offerDate,
            "offer_ctc": offerCTC
        }

        axios.post(`${config.API_URL}${extendOffer ? 'extendJobOffer' : 'offerJob'}`, payloads, apiHeaderToken(config.API_TOKEN))
            .then((response) => {
                if (response.status === 200) {
                    setOfferCTC('');
                    setOfferDate(null);
                    handleCloseOfferModels();
                    dispatch(FetchCandidatesListById(id));
                    if (!extendOffer) {
                        handleNext()
                        handleNext()
                    }
                    setExtendOffer(false);
                    return toast.success(response.data.message)
                }
            })
            .catch(err => {
                toast.error(err.response.data.message)
            })
    }


    return (
        <>
            <div className="steps_interviewrap sitecard pr-0 ps-0 mb-3 position-relative">
                <div className="d-flex flex-column steps_intervw_hdr gap-1 px-3">
                    <div className="location">
                        <span>{interviewStep && changeJobTypeLabel(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.job_type)}</span>
                    </div>
                    <h4 className="mb-0">{interviewStep && interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.job_title}</h4>
                    <div className="dflexbtwn">
                        <span> {interviewStep && interviewStep?.location} </span>
                        <span>{interviewStep && DateFormate(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.add_date)}</span>
                    </div>
                </div>
                <Box sx={{ width: "100%" }} className="px-3">
                    <Stepper
                        activeStep={activeStep}
                        connector={<ColorlibConnector />}
                        orientation="vertical"
                        disabled={isStepperDisabled}
                    >
                        {/* Your steps */}
                        {steps.map((step, index) => (
                            <Step key={step.label} active={activeStep === index} completed={activeStep > index} >
                                <StepLabel StepIconComponent={CustomStepIcon}>
                                    <h6 style={{ color: activeStep === index ? '#30A9E2' : '#ccc' }}>{step.label === 'Offer' ? `${step.label} ${interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.offer_status ? `(${interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.offer_status})` : '' }` : step.label }</h6>
                                    <span className={activeStep === index ? 'allw' : 'notallw'}> {step.description}</span>
                                    <br />
                                    {
                                        // Safely check for 'Rejected' status and 'Applied' label
                                        (interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.form_status === "Rejected" && step.label === 'Applied') &&
                                        <span style={{ color: 'red !important' }}><p style={{ color: 'red' }}>Candidate Rejected</p></span>
                                    }
                                </StepLabel>
                                <StepContent>
                                    <div className="read-btn">
                                        {/* href={step.label === 'Shortlisted' && `/schedule-interview/${interviewStep?.job_id}?userId=${interviewStep?._id}&applied-job-id=${interviewStep && interviewStep?.applied_jobs[0]?._id}`} */}

                                        {
                                            step.label === 'Shortlisted' ?
                                                <Button className="btn">
                                                    <Link className="text-white" to={`/schedule-interview/${interviewStep?.job_id}?userId=${interviewStep?._id}&applied-job-id=${interviewStep && interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?._id}`}>{step.btntext}</Link>
                                                </Button>
                                                :
                                                <Button className="btn"
                                                    onClick={(e) => {
                                                        // handleNext(e, step.status)
                                                        if (step.status === 'Applied') {
                                                            handleShortListCandidate()
                                                        }
                                                        if (step.status === 'Interview') {
                                                            handleShowOfferModel();
                                                        }
                                                        if (step.status === 'Offer') {
                                                            // HandleNavigate();
                                                            if(['Pending' , 'Rejected'].includes(interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0].offer_status)){
                                                                handleReject()
                                                            }else {
                                                                HandleNavigate();
                                                            }
                                                        }
                                                        // if (step.status === 'Hired') {
                                                        //     HandleNavigate();
                                                        // }
                                                        if (step.status === 'Interview') {
                                                            handleShowOfferModel();
                                                        }
                                                    }}
                                                    sx={{ mt: 1, mr: 1 }}
                                                    disabled={isStepperDisabled || interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.form_status === "Hired"}
                                                >
                                                    {
                                                        Array.isArray(interviewStep?.applied_jobs) &&
                                                            interviewStep.applied_jobs.length > 0 &&
                                                            interviewStep?.applied_jobs?.filter((item) => item.job_id === interviewStep?.job_id)[0]?.form_status === "Rejected"
                                                            ? <span className='text-danger'>Candidate Rejected</span>
                                                            : step.btntext
                                                    }
                                                </Button>
                                        }
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </div>


            {/* Send Offer MOdels */}
            <Modal
                show={offerModel}
                onHide={handleCloseOfferModels}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {extendOffer ? 'Extend Joining Date' : 'Send Offer'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <div className="col-sm-12">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Date of Onboarding</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FaRegClock />
                                            </InputGroup.Text>
                                            <Form.Control type="date" placeholder="Select a date" value={offerDate} onChange={(e) => {
                                                setOfferDate(e.target.value)
                                            }} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3 custom-select" controlId="exampleForm.ControlSelect1">
                                        <Form.Label> Designation </Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={offerDesignation}
                                            onChange={(e) => setOfferDesignation(e.target.value)}
                                            readOnly
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                        <Form.Label>CTC Per annum</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <LiaRupeeSignSolid />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Please Enter the CTC"
                                                value={offerCTC}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        setOfferCTC(value); // Update only if the value contains digits only
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    // Prevent any non-digit key presses
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                // readOnly={extendOffer}
                                                disabled={extendOffer}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row> */}

                            {!extendOffer && (
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                            <Form.Label>CTC Per annum</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <LiaRupeeSignSolid />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please Enter the CTC"
                                                    value={offerCTC}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            setOfferCTC(value); // Update only if the value contains digits only
                                                        }
                                                    }}
                                                    onKeyPress={(e) => {
                                                        // Prevent any non-digit key presses
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    disabled={extendOffer}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                        </Form>
                        <div className="text-center " onClick={handleSendOfferLatter}>
                            <button type="button" class="sitebtn mt-4 btn btn-primary ratebtn"> <CheckCircleIcon /> Submit </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

