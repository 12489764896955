import React, { useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link } from 'react-router-dom';
import { RiEyeLine } from "react-icons/ri";
import ViewofferModal from './OfferModlesViews';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function Offer_table({ PageStatus, setCandidatesDetials, filterText }) {

    const AppliedJobs = useSelector((state) => state.appliedJobList.AppliedCandidate)
    const [data, setData] = useState(null);


    const [show, setShow] = useState(false);

    const handleShow = (e, data) => {
        e.preventDefault()
        setShow(true);
        setData(data)
    };

    const rows = AppliedJobs.status === 'success' && AppliedJobs.data.length !== 0
        ? AppliedJobs.data
            .filter(value => value.form_status === PageStatus)  // Filter based on PageStatus
            .map((value, index) => (
                {
                    id: index + 1,
                    candidateInfo: {
                        name: value.name,
                        status: value?.applied_jobs?.find((item) => item?.job_id === value?.job_id)?.form_status,
                        email: value.email,
                        phone: value.mobile_no,
                    },
                    value: value,
                    "Date of Onboarding": moment(value?.applied_jobs?.find((item) => item?.job_id === value?.job_id)?.onboard_date).format('DD/MM/YYYY'),
                    "Designation": value?.designation,
                    "Project": value?.project_name,
                    "Location": value?.location,
                    "Notice Period": value.notice_period,
                    "Department": value?.department,
                    "Status": "",
                }
            ))
        : [];

    const filteredRows = rows.filter((row) =>
        row.candidateInfo?.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const columns = [
        { field: "id", headerName: "Sno.", width: 50 },
        {
            field: "candidateName",
            headerName: "Candidate Name",
            width: 200,
            renderCell: (params) => (
                <div className="candinfo prcnt_bar">
                    <Link to={`/candidate-profile/${params.row?.value?._id}`}><p className="color-blue">{params.row?.candidateInfo?.name}</p></Link>
                </div>
            ),
        },
        {
            field: "Email & Phone Number",
            headerName: "Email & Phone Number",
            width: 220,
            renderCell: (params) => (
                <div className="candinfo">
                    <p>{params.row?.candidateInfo?.email}</p>
                    <span>{params?.row?.candidateInfo?.phone}</span>
                </div>
            ),
        },
        {
            field: "Date of Onboarding",
            headerName: "Date of Onboarding",
            type: "number",
            width: 80,
        },
        {
            field: "Designation",
            headerName: "Designation",
            type: "number",
            width: 200,
        },
        {
            field: "Project",
            headerName: "Project",
            type: "number",
            width: 200,
        },
        {
            field: "Department",
            headerName: "Department",
            type: "number",
            width: 100,
        },
        {
            field: "Location",
            headerName: "Location",
            type: "number",
            width: 120,
        },
        {
            field: "Status",
            headerName: "Status",
            type: "number",
            width: 120,
            renderCell: (params) => (
                PageStatus === 'Rejected' ?
                    <div className="candinfo">
                        <span className="statustag bgdred">{params?.row?.candidateInfo?.status}</span>
                    </div> :
                    PageStatus === 'Hired' ?
                        <div className="candinfo">
                            <span className="statustag bgdgreen">{params?.row?.candidateInfo?.status}</span>
                        </div> : <div className="candinfo prcnt_bar">
                            <span className="statustag">{params?.row?.candidateInfo?.status}</span>
                        </div>
            ),
        },
    ];

    // handle the Offer Table in case of if data reject and then offer models are not showing
    if (!['Rejected', 'Hired'].includes(PageStatus)) {
        columns.push(
            {
                field: "View Offer",
                headerName: "View Offer",
                width: 80,
                renderCell: (params) => (
                    <div className="d-flex flex-column justify-content-end align-items-center">
                        <div className="h-100" onClick={(e) => handleShow(e, params?.row?.value)}>
                            <RiEyeLine className="fs-5" />
                        </div>
                    </div>
                ),
            }
        )
    }

    return (
        <>
            <div className="w-100">
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    loading={AppliedJobs?.status === 'loading'}
                    headerClassName="custom-header-class"
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20]}
                    checkboxSelection
                />
            </div>
            <ViewofferModal show={show} onHide={() => setShow(false)} data={data} />
        </>
    )
}

